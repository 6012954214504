
import { defineComponent } from 'vue';
import store from "@/store";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import MultiSelect from 'primevue/multiselect';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import CustomerService from "@/services/CustomerService";
import AddressItems from '../UI/AddressItems.vue';
import TaxCodes from '../Sales/Orders/TaxCodes.vue';

const customerService = new CustomerService();

export default defineComponent({
  name: 'AddShippingAddressDialog',

  components: {
    Dialog,
    InputText,
    Dropdown,
    Checkbox,
    MultiSelect,
    Button,
    InputSwitch,
    AddressItems,
    TaxCodes
  },

  props: {
    header: {
      type: String,
      default: 'Address',
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
    addAddressToCustomerSwitch: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: String,
      default: '',
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shipAddress: {
      type: Object,
      default: () => {
        return {
          ship_seq: "",
          ship_city: "",
          ship_state: "",
          ship_zip: "",
          ship_name: "",
          ship_country: "",
          ship_attn: "",
          ship_comm: "",
          ship_to_items: [{ ship_to: "" }],
          ship_email: "",
          ship_phone: "",
          ship_fax: "",
          ship_to_tax_items: [],
          ship_acct_no_items: [],
        };
      },
    },
  },
  mounted() {
    this.show = this.showDialog;
    this.ship_city = this.shipAddress.ship_city;
    this.ship_state = this.shipAddress.ship_state;
    this.ship_zip = this.shipAddress.ship_zip;
    this.ship_name = this.shipAddress.ship_name;
    this.ship_country = this.shipAddress.ship_country;
    this.ship_seq = this.shipAddress.ship_seq;
    this.ship_email = this.shipAddress.ship_email;
    this.ship_phone = this.shipAddress.ship_phone;
    this.ship_fax = this.shipAddress.ship_fax;
    this.ship_comm = this.shipAddress.ship_comm;
    this.ship_attn = this.shipAddress.ship_attn;
    this.ship_to_items = this.shipAddress.ship_to_items;
    this.ship_to_tax_items = this.shipAddress.ship_to_tax_items || [];
  },
  computed: {
    countriesData() {
      const countries = store.getters["customerInquiry/getCountries"];
      return countries;
    },
    getTaxCodes() {
      const taxCodes = store.getters["customerInquiry/getStax"];
      return taxCodes;
    },
    shipTaxCodes() {
      return this.ship_to_tax_items.map((ele: any) =>  {
        return {
          tax_codes: ele.ship_to_tax,
          tax_code_rate: ele.tax_code_rate
        }
      });
    }
  },

  data(){
    return {
      thirdPartyList: Array<any>(),
      addAddressToCustomer: false,
      isSubmitting: false,
      countryCode: "",
      isSubmitted: false,
      show: false,
      newShippingAddressModified: false,
      ship_seq: "",
      ship_city: "",
      ship_state: "",
      ship_zip: "",
      ship_name: "",
      ship_country: "",
      ship_attn: "",
      ship_comm: "",
      ship_to_items: [
        {  ship_to: ""  }
      ],
      ship_email: "",
      ship_phone: "",
      ship_fax: "",
      ship_to_tax_items: [],
      ship_acct_no_items: []
    }
  },

  emits: ["closeDialog", "saveAddress"],

  watch: {
    async customerId(newVal) {
      if (newVal) {
        await this.fetchCustomerData();
      }
    },
    showDialog(newVal) {
      this.show = newVal;
    },
    shipAddress: {
      handler(newValue) {
        if (!this.newShippingAddressModified && this.editMode) {
          this.ship_city = newValue.ship_city;
          this.ship_state = newValue.ship_state;
          this.ship_zip = newValue.ship_zip;
          this.ship_name = newValue.ship_name;
          this.ship_country = newValue.ship_country;
          this.ship_seq = newValue.ship_seq;
          this.ship_email = newValue.ship_email;
          this.ship_phone = newValue.ship_phone;
          this.ship_fax = newValue.ship_fax;
          this.ship_comm = newValue.ship_comm;
          this.ship_attn = newValue.ship_attn;
          this.ship_to_items = newValue.ship_to_items;
          this.ship_to_tax_items = newValue.ship_to_tax_items || [];
        }
      },
      deep: true,
    },
    editMode: {
      handler(newValue){
        if(newValue === false){
          this.clearNewShippingAddress()
        }
      }
    }
  },

  methods: {
    fetchCustomerData() {
      customerService
        .getCustomer(
          this.customerId,
          store.getters["session/getClient"],
          "saleopp_ids soquote_ids so_ids ticket_ids ar_ids rma_ids log_ids highest_ar_bal avg_ar_bal"
        )
        .then((response: any) => {
          let original = response.carrier_items;
          let reformatted: Array<any> = [];
          original?.forEach((carrier: any) => {
            carrier.frt_account_items?.forEach((part: any) => {
              let partObject = {
                frt_account: part.frt_account,
                carrier: carrier.carrier,
              };
              reformatted.push(partObject);
            });
          });
          this.thirdPartyList = JSON.parse(JSON.stringify(reformatted));
        });
    },
    getCountries() {
      const countryOptions = this.countriesData?.map(
        (element: any) => element.country
      );
      return countryOptions;
    },
    getCountryCode(event: any) {
      for (let i = 0; i < this.countriesData.length; i++) {
        if (event === this.countriesData[i].country) {
          this.countryCode = this.countriesData[i].country_code;
          return this.countryCode;
        }
      }
    },
    updateShipAddress(address: any) {
      this.ship_to_items = address;
    },
    emailValidation(email: any) {
      if (email == null) {
        return true;
      } else {
        return /^\S+@\S+\.\S+$/.test(email);
      }
    },
    updateTaxCodes(taxCodes: any) {
      this.ship_to_tax_items = taxCodes.map((ele: any) => {
        return { ship_to_tax: ele.tax_codes,
          tax_code_rate: ele.tax_code_rate};
      })
    },
    closeAddressDialog () {
      this.show = false
      this.isSubmitted = false
      this.$emit("closeDialog")
    },
    onInputChange() {
      if(this.editMode){
        this.newShippingAddressModified = true;
      }else{
        this.newShippingAddressModified = false;
      }
    },
    saveAddressInfo () {
      this.isSubmitted = true
      if ( this.ship_email && !this.emailValidation(this.ship_email)) {
        return
      }

      this.$emit("saveAddress", {
          ship_seq: this.ship_seq,
          ship_city: this.ship_city,
          ship_state: this.ship_state,
          ship_zip: this.ship_zip,
          ship_name: this.ship_name,
          ship_country: this.ship_country,
          ship_attn: this.ship_attn,
          ship_comm: this.ship_comm,
          ship_to_items: this.ship_to_items,
          ship_email: this.ship_email,
          ship_phone: this.ship_phone,
          ship_fax: this.ship_fax,
          ship_to_tax_items: this.ship_to_tax_items,
          ship_acct_no_items: this.ship_acct_no_items}, this.addAddressToCustomer)
      this.closeAddressDialog()
      this.newShippingAddressModified = false;
    },
    clearNewShippingAddress () {
        this.ship_seq = "";
        this.ship_city= "";
        this.ship_state= "";
        this.ship_zip= "";
        this.ship_name= "";
        this.ship_country= "";
        this.ship_attn= "";
        this.ship_comm= "";
        this.ship_to_items= [
        {  ship_to: ""  }
        ],
        this.ship_email= "";
        this.ship_phone= "";
        this.ship_fax= "";
        this.ship_to_tax_items= [];
        this.ship_acct_no_items= []; 
      },

    getTaxCodeOptions() {
      const taxCodeOptions =
        this.getTaxCodes != null
          ? this.getTaxCodes.map((ele: any) => ele.id)
          : [];
      const distinctTaxCodeOptions = [...new Set(taxCodeOptions)];
      const taxCodes: Array<any> = [];
      for (let i = 0; i < distinctTaxCodeOptions.length; i++) {
        taxCodes.push({ ship_to_tax: distinctTaxCodeOptions[i] });
      }
      return taxCodes;
    },

    getCarrierOptions() {
      const carrierOptions =
        this.thirdPartyList != null
          ? this.thirdPartyList.map((element: any) => {
              return {
                ship_carrier: element.carrier,
                ship_acct_no: element.frt_account,
              };
            })
          : [];
      return carrierOptions;
    },
  },
})


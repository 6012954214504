
import { defineComponent } from "vue";
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Row from "primevue/row";
import ColumnGroup from "primevue/columngroup";
import AddTaxDialog from "./AddTaxDialog.vue";

import Utils from "@/utility/utils";

import { mapGetters } from "vuex";

export default defineComponent({
  name: "TaxCodes",
  components: {
    Button,
    DataTable,
    Column,
    ColumnGroup,
    Row,
    AddTaxDialog,
  },
  props: {
    taxCodesItems: {
      type: Array,
      required: true,
    }
  },
  computed: {
    ...mapGetters({
      shipViaItems: "shipControl/getShipViaItems",
    }),
    totalTaxPercent() {
      return (
        Utils.reduceTotal(
          this.taxCodesItems,
          "tax_code_rate"
        ).toFixed(2) + "%"
      );
    },
    totalTaxAmount() {
      return this.tax_amount;
    },

  },
  data() {
    return {
      tax_amount: "",
      loadingSalesTax: false,
    };
  },
  methods: {
    addTax(data: any) {
      let tax_codes = []
      if (!this.taxCodesItems) {
        tax_codes = [
          { tax_codes: data.code, tax_code_rate: data.rate},
        ];
      } else {
        tax_codes = [...this.taxCodesItems, { tax_codes: data.code, tax_code_rate: data.rate}];
      }
      this.$emit("updateTaxCodes", tax_codes);
    },
    deleteTax(code: string | number) {
      const tax_codes =
        this.taxCodesItems.filter(
          (element: any) => element.tax_codes !== code
        );
        this.$emit("updateTaxCodes", tax_codes);
    },
  },
});
